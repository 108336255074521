import {Controller} from "stimulus"
import axios from "axios"

export default class extends Controller {
  static values = {url: String}

  connect() {
    const observer = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.load()
              observer.unobserve(entry.target)
            }
          })
        }
    )
    observer.observe(this.element)
  }

  load() {
    axios.get(this.urlValue)
        .then((response) => {
          const div = document.createElement('div')
          div.innerHTML = response.data.trim()
          div.childNodes.forEach(el => {
            this.element.before(el)
          })

          this.element.remove()
        })
  }
}
